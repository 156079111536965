import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Exam } from '../models/exam.model';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class ExamsService {
  readonly baseUrl = `${environment.mobileApi}api/examination/`;

  constructor(private http: HttpClient, private userService: UserService) {}

  public getExams() {
    return this.http.get<Exam[]>(`${this.baseUrl}`);
  }

  public getExam(id: number) {
    return this.http.get<Exam>(`${this.baseUrl}exam/${id}`);
  }

  public getTeacherExams(teacher: string) {
    return this.http.get<Exam[]>(`${this.baseUrl}${teacher}`);
  }

  public getTeacherExamsExtended(teacher: string) {
    return this.http.get<Exam[]>(`${this.baseUrl}extended?Teacher=${teacher}&ShowUpcoming=true`);
  }

  public lock(examinationSheetId: number, group: string) {
    return this.http.put(`${this.baseUrl}lock`, { id: examinationSheetId, group });
  }

  public print(examId: number) {

    window.location.href = `${environment.mobileApi}pdf?id= + ${examId}`;
  }

  public sendToEmail(examId: number) {
    const user = this.userService.getUserData();
    return this.http.get(`${environment.mobileApi}pdf/SendToEmail?id= + ${examId}&userCode=${user.employeeId}`);
  }
}
