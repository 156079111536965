import { CandidateModel } from './candidate.model';

export interface VoteModel {
  id: number;
  description: string;
  createDate: string;
  votingEndDate: string;
  type: VotingType;
  candidates: CandidateModel[];
}

export enum VotingType {
  default = 0,
  education = 1,
}
