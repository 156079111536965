export class ScheduleModel {
    isCurrentWeekEven: boolean;
    evenWeek: DayClass[];
    oddWeek: DayClass[];
    created: Date;
    teacherName: string;
}

export class DayClass {
    dayNumber: number;
    pairNumber: number;
    className: string;
    cabinetNumber: string;
    teacherName: string;
    classType: string;
    classTime: string;
    group: string;
}

export const daysDisplay = [
    {dayNumber: 1, display: 'Понедельник', short: 'ПН'},
    {dayNumber: 2, display: 'Вторник', short: 'ВТ'},
    {dayNumber: 3, display: 'Среда', short: 'СР'},
    {dayNumber: 4, display: 'Четверг', short: 'ЧТ'},
    {dayNumber: 5, display: 'Пятница', short: 'ПТ'},
    {dayNumber: 6, display: 'Суббота', short: 'СБ'},
];
