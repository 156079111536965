import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, of } from 'rxjs';
import { ScheduleModel } from '../../models/schedule/schedule';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ScheduleService {
  baseUrl = environment.mobileApi;

  private readonly scheduleTeacher: string = 'scheduleTeacher';
  private readonly scheduleStudent: string = 'scheduleStudent';

  constructor(private httpClient: HttpClient) {}

  getIsWeekEven(): Observable<boolean> {
    return this.httpClient.get<boolean>(
      `${this.baseUrl}api/Schedule/IsWeekEven`
    );
  }

  getScheduleByGroup(groupNumber: string): Observable<ScheduleModel> {
    let modelStr = localStorage.getItem(this.scheduleStudent);
    let m:ScheduleModel = null;
    if (modelStr) {
      m = JSON.parse(modelStr);
      let lastCreated = new Date(m.created);
      lastCreated.setDate(lastCreated.getDate() + 1);
      if (lastCreated > new Date()) {
        return of(m);
      }
    }
    let headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    return this.httpClient
      .get<ScheduleModel>(`${this.baseUrl}api/Schedule/group/${groupNumber}`, {
        headers: headers,
      })
      .pipe(
        map((st) => {
          m = st;
          if (!st) {
            return null;
          }
          st.created = new Date();
          localStorage.setItem(this.scheduleStudent, JSON.stringify(st));
          return st;
        })
      );
  }

  getScheduleStudentFromCache() {
    const modelStr = localStorage.getItem(this.scheduleStudent);
    if (modelStr) {
      const m: ScheduleModel = JSON.parse(modelStr);
      return m;
    }
  }

  getScheduleTeacher(teacher: string): Observable<ScheduleModel> {
    // cache by local storage
    const modelStr = localStorage.getItem(this.scheduleTeacher);
    if (modelStr) {
      const m: ScheduleModel = JSON.parse(modelStr);
      const lastCreated = new Date(m.created);
      lastCreated.setDate(lastCreated.getDate() + 1);
      if (lastCreated > new Date() && m.teacherName === teacher) {
        return of(m);
      }
    }
    return this.httpClient
      .get<ScheduleModel>(`${this.baseUrl}api/Schedule/Teacher/${teacher}`)
      .pipe(
        map((st) => {
          st.created = new Date();
          st.teacherName = teacher;
          localStorage.setItem(this.scheduleTeacher, JSON.stringify(st));
          return st;
        })
      );
  }
}
